<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Thank You
        </h2>
        <p class="mb-2">
          We have received the information.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="`/creative-briefing/${opportunityId}/${creativeBriefingId}`"
        >
          Back to form
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    opportunityId() {
      return this.$route.params.oppId
    },

    creativeBriefingId() {
      return this.$route.params.id
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
